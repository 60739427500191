const host = window.location.host;
var _client = host.split(".")[host.startsWith("www") ? 1 : 0];

if (process.env.NODE_ENV == "development") {
    // _client = "dalkiaconl";
    // _client = "simt";
    // _client = "demo";
    _client = "dijon";
}

const isStarted = () => {
    // TODO: kicker le 1er decembre
    if (_client === "innov-events") return true;
    if (process.env.NODE_ENV == "development") return true;

    return new Date() >= new Date(2023, 11, 1, 8);
};

const getCurrentClient = () => _client;
const getLogoUrl = () => require(`@/assets/clients/${_client}/logo.png`);
const getBgHomeUrl = () => require(`@/assets/clients/${_client}/bg-home.jpg`);
const getBgCalendarUrl = () => require(`@/assets/clients/${_client}/bg-calendar.jpg`);
const getBgDayUrl = () => require(`@/assets/clients/${_client}/bg-day.jpg`);
const getImgAccueilUrl = () => {
    let url;
    try {
        url = require(`@/assets/clients/${_client}/accueil.jpg`);
    } catch (error) {
        url = "";
    }

    return url;
};

export {
    getCurrentClient,
    getLogoUrl,
    getBgHomeUrl,
    getImgAccueilUrl,
    getBgCalendarUrl,
    getBgDayUrl,
    isStarted,
};
