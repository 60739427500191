<template>
    <div id="app" :style="cssVars">
        <div v-if="dev_mode" class="position-fixed" style="top: 10px; left: 10px; z-index: 9999">
            <span class="badge bg-primary d-block d-sm-none">xs</span>
            <span class="badge bg-secondary d-none d-sm-block d-md-none">sm</span>
            <span class="badge bg-success d-none d-md-block d-lg-none">md</span>
            <span class="badge bg-dark d-none d-lg-block d-xl-none">lg</span>
            <span class="badge bg-info d-none d-xl-block">xl</span>
        </div>

        <div v-if="isLoading" class="loader d-flex-center">
            <div class="loader__box d-flex-center flex-column rounded p-3 pt-5">
                <i class="fa-duotone fa-solid fa-hat-santa fa-bounce fa-2xl"></i>
                <span class="mt-4--5 fs-4 fw-bold">Chargement..</span>
            </div>
        </div>

        <div class="position-relative flex-fill d-flex flex-column" v-bind:class="`${client}`">
            <template v-if="$route.matched.length">
                <nav class="navbar navbar-dark navbar-expand-sm">
                    <div class="container-fluid">
                        <router-link to="/" class="navbar-brand">
                            <img :src="logoUrl" class="" alt="Logo entreprise" />
                        </router-link>
                        <button
                            class="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup"
                            aria-controls="navbarNavAltMarkup"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div class="navbar-nav ms-end align-items-center">
                                <router-link to="/" class="nav-link" aria-label="ACCUEIL">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        ACCUEIL
                                    </span>
                                </router-link>
                                <router-link to="/calendrier" class="nav-link" aria-label="CALENDRIER">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        CALENDRIER
                                    </span>
                                </router-link>
                            </div>
                            <div class="navbar-nav ms-auto align-items-center">
                                <span v-if="isLoggedIn && isAdmin" class="badge bg-light text-dark">ADMIN</span>
                                <router-link v-if="!isLoggedIn" to="/login" class="nav-link" aria-label="CONNEXION">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        CONNEXION
                                    </span>
                                </router-link>
                                <router-link v-if="isLoggedIn" to="/logout" class="nav-link" aria-label="DÉCONNEXION">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        DÉCONNEXION
                                    </span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </nav>

                <router-view ref="view" class="flex-fill body-container"></router-view>
            </template>
            <template v-else-if="isLoggedIn">
                <NotFound />
            </template>
        </div>
    </div>
</template>

<script>
import auth from "./services/auth";
import loader from "./services/loader";
import NotFound from "./components/_404";
import {
    getCurrentClient,
    getLogoUrl,
    getBgHomeUrl,
    getBgDayUrl,
    getBgCalendarUrl,
    isStarted,
} from "./services/client";

export default {
    components: {
        NotFound,
    },
    data: () => {
        const _client = getCurrentClient();

        return {
            isLoggedIn: auth.getIfLoggedIn(),
            isAdmin: auth.getIfIsAdmin(),
            client: _client,
            logoUrl: getLogoUrl(_client),
            isStarted: isStarted(),
            isLoading: false,
            dev_mode: process.env.NODE_ENV == "development",
        };
    },
    created() {
        auth.onChange = (isLoggedIn) => {
            this.isLoggedIn = isLoggedIn;
            this.isAdmin = auth.getIfIsAdmin();
        };
        loader.showLoader = () => (this.isLoading = true);
        loader.hideLoader = () => (this.isLoading = false);
        loader.isLoading = () => this.isLoading;
    },
    computed: {
        cssVars() {
            let bgHomeUrl = getBgHomeUrl(this.client);
            let bgDayUrl = getBgDayUrl(this.client);
            let bgCalendarUrl = getBgCalendarUrl(this.client);

            return {
                "--bg-home": `url(${bgHomeUrl}) no-repeat center center`,
                "--bg-day": `url(${bgDayUrl}) no-repeat center center`,
                "--bg-calendar": `url(${bgCalendarUrl}) no-repeat center center`,
            };
        },
    },
};
</script>

<style>
.login:before,
.home:before {
    background: var(--bg-home);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.calendrier:before {
    background: var(--bg-calendar);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.opened-case:before {
    background: var(--bg-day);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
</style>
