<template>
    <div class="opened-case flex-fill position-relative">
        <div class="content d-flex-center flex-fill h-100" v-if="contenu">
            <div
                class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg text-center"
                style="min-width: 600px; max-width: 800px"
            >
                <h1 class="mt-3 mb-2 text-white label-date">{{ id ? "Modification" : "Création" }} de la section</h1>

                <div class="d-flex flex-column align-items-center w-100">
                    <div class="row">
                        <div class="col-auto text-white d-flex align-items-center">
                            <label>Type de la section :</label>
                        </div>
                        <div class="col">
                            <div
                                class="col-auto col-sm d-flex flex-column flex-sm-row align-items-sm-center align-items-stretch justify-content-sm-end justify-content-center"
                            >
                                <button
                                    v-for="type in types"
                                    v-bind:key="type.value"
                                    type="button"
                                    class="btn mx-sm-2 my-1 text-nowrap"
                                    v-bind:class="[selectedType === type.value ? 'btn-white' : 'btn-outline-white']"
                                    @click="select_type(type.value)"
                                    :disabled="!!id"
                                >
                                    {{ type.text }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div id="editors" class="align-self-start w-100 my-3">
                        <div v-if="selectedType == 'text'">
                            <VueEditor class="bg-white" v-model="texte" />
                        </div>

                        <div class="text-start text-white" v-else-if="selectedType == 'video'">
                            <div class="mb-3">
                                <label for="url_youtube" class="form-label">Url de la vidéo YouTube :</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="url_youtube"
                                    v-model="urlYoutube"
                                    placeholder="https://www.youtube.com/embed/[ID_VIDEO]"
                                />
                                <small>
                                    ID_VIDEO : identifiant de la vidéo YouTube que vous pouvez retrouver dans l'url de
                                    votre vidéo
                                </small>
                            </div>

                            <div
                                class="w-100 mt-2 text-white small cursor-pointer text-left"
                                @click="urlYoutube = 'https://www.youtube.com/embed/'"
                            >
                                Cliquer pour préremplir <u>https://www.youtube.com/embed/</u>
                            </div>
                        </div>
                    </div>
                    <div>
                        <router-link
                            tag="button"
                            class="btn btn-normal text-white my-3"
                            :to="{ name: 'case-editor', params: { day } }"
                        >
                            <i class="fa-duotone fa-solid fa-xmark"></i>
                            <span class="ms-2">Annuler</span>
                        </router-link>
                        <button @click="saveSection" v-bind:class="`btn-${client}`" class="btn text-white ms-3">
                            <i class="fa-duotone fa-solid fa-check"></i>
                            <span class="ms-2">Enregistrer</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="content w-100 position-absolute" v-if="!contenu" style="top: 35%">
            <div class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg mw-100 text-center">
                <h1 class="mt-3 mb-2 label-date">Un peu de patience...</h1>
            </div>
        </div>

        <div class="x" v-if="!contenu">
            <img class="y" src="@/assets/img/gift.png" alt="codepen" />
        </div>
    </div>
</template>

<script>
import router from "../services/router";
import auth from "../services/auth";
import loader from "../services/loader";
import { getCurrentClient } from "../services/client";
import { getSectionContent, saveSectionContent } from "../services/cases-service";
import { VueEditor } from "vue2-editor";

const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export default {
    components: {
        VueEditor,
    },
    data: function () {
        const day = this.$route.params.day;
        const id = this.$route.params.id;
        const ordre = this.$route.params.index;
        const currentDate = new Date(Date.UTC(2023, 11, day, 0, 0, 0));
        const client = getCurrentClient();

        const options = {
            weekday: "long",
            month: "long",
            day: "numeric",
        };

        return {
            currentDate: capitalize(currentDate.toLocaleDateString("fr-FR", options)),
            day,
            id,
            client,
            contenu: null,
            types: [
                { value: "text", text: "Texte" },
                { value: "video", text: "Vidéo YouTube" },
            ],
            ordre,
            selectedType: null,
            urlYoutube: null,
            texte: null,
            urlImage: null,
        };
    },
    methods: {
        select_type: function (selection) {
            this.selectedType = selection;
        },
        saveSection: async function () {
            loader.showLoader();

            const contenu = {
                id: this.id,
                ordre: +this.ordre,
                jour: +this.day,
                type: this.selectedType,
                valeur: "",
                url: "",
            };

            switch (contenu.type) {
                case "text":
                    contenu.valeur = this.texte;
                    break;
                case "video":
                    contenu.url = this.urlYoutube;
                    break;
            }

            await saveSectionContent({ contenu, token: auth.getToken() })
                .then((result) => {
                    const success = result.data;
                    if (success) {
                        router.push({ name: "case-editor", params: { day: this.day } });
                    } else {
                        alert("KO");
                    }
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });

            loader.hideLoader();
        },
    },
    asyncComputed: {
        async contenu() {
            var _contenu;

            this.types = [
                { value: "text", text: "Texte", disabled: !!this.id },
                { value: "video", text: "Vidéo YouTube", disabled: !!this.id },
            ];

            // si la section existe on la charge depuis la base
            if (this.id) {
                await getSectionContent({ id: this.id, token: auth.getToken() })
                    .then((result) => {
                        const data = result.data;
                        _contenu = data;
                    })
                    .catch(function (error) {
                        console.error("Une erreur est survenue", error.code, error.message, error.details);
                    });

                switch (_contenu.type) {
                    case "text":
                        this.texte = _contenu.valeur;
                        break;
                    case "video":
                        this.urlYoutube = _contenu.url;
                        break;
                }

                this.selectedType = _contenu.type;

                return _contenu;
            } else {
                this.selectedType = "text";

                _contenu = {
                    client: getCurrentClient(),
                    jour: this.day,
                    ordre: this.ordre,
                    type: "text",
                    valeur: null,
                };
            }

            return _contenu;
        },
    },
};
</script>
