<template>
    <div>
        <img
            v-if="number == 1"
            src="https://media0.giphy.com/media/jhYV9I9NSbqik/giphy.gif?cid=790b76113b69b029e9dbcfa5aaca5ab822af8621067dd9a5&rid=giphy.gif&ct=g"
            class="mw-100"
            style="width:400px"
            alt=""
        />
        <img
            v-if="number == 2"
            src="https://i.giphy.com/media/5xtDaryAMLjvAyN4eiY/giphy.webp"
            class="mw-100"
            style="width:400px"
            alt=""
        />
        <img
            v-if="number == 3"
            src="https://i.giphy.com/media/137MDVkGCzkZvW/giphy.webp"
            class="mw-100"
            style="width:300px"
            alt=""
        />
        <img
            v-if="number == 4"
            src="https://i.giphy.com/media/3oz8xyUoD2HlTIcdTW/giphy.webp"
            class="mw-100"
            style="width:400px"
            alt=""
        />
        <div class="text-center h2 label-date fw-bold mt-2">Case verrouillée !</div>
    </div>
</template>

<script>
function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

export default {
    data() {
        return {
            number: 1,
        };
    },
    created() {
        this.number = getRandomInt(4) + 1;
    },
};
</script>
