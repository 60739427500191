<template>
    <div class="opened-case flex-fill position-relative text-white">
        <div v-if="displayModal" class="modal d-flex-center">
            <div style="width: 400px" class="modal__box d-flex-center flex-column rounded p-3">
                <h5>Êtes-vous sûr de vouloir supprimer cette section ?</h5>
                <div class="d-flex align-items-center mt-3">
                    <button class="btn btn-danger" @click="closeDeleteModal()">
                        <i class="fa-solid fa-xmark"></i>
                        <span class="ms-2">Annuler</span>
                    </button>

                    <button class="btn btn-success ms-3" @click="deleteSection()">
                        <i class="fa-solid fa-check"></i>
                        <span class="ms-2">Confirmer</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="content d-flex-center flex-fill h-100" v-if="elements">
            <div class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg" style="max-width: 800px">
                <h1 class="mt-3 mb-2 text-white label-date text-center">Edition du {{ currentDate }}</h1>

                <button v-bind:class="`btn-${client}`" class="btn text-white mb-5" @click="openPreview">
                    <i class="fa-solid fa-arrow-up-right-from-square"></i>

                    <span class="ms-2">Aperçu</span>
                </button>

                <div
                    class="p-2 text-center border rounded border-bordeau d-flex flex-column fw-bold mb-3"
                    style="border-width: 4px !important"
                >
                    <div>La taille maximale affichée pour le visuels est de 700px.</div>
                    <div>Il est donc conseillé de respecter ce format.</div>

                    <div>
                        Utiliser des images les plus légères possible (&lt;150ko) afin de ne pas impacter les
                        performances lors de l'ouverture des cases
                    </div>
                </div>

                <div class="d-flex flex-column">
                    <VueEditor class="invisible" style="height: 0px" />

                    <div v-for="(element, index) in elements" :key="element.ordre">
                        <div class="row">
                            <div class="col text-center">
                                <button @click="addSection(index)" class="btn btn-sm btn-secondary text-white">
                                    <i class="fa-solid fa-plus"></i>

                                    <span class="ms-2">Ajouter une section ici</span>
                                </button>
                            </div>

                            <div class="col-12 my-2">
                                <hr class="border-hr" />
                            </div>

                            <div class="col d-flex justify-content-center">
                                <div class="ql-snow" v-if="element.type == 'text'">
                                    <div v-html="element.valeur" class="text-content ql-editor p-1"></div>
                                </div>

                                <div v-else-if="element.type == 'video'" class="text-center">
                                    <iframe
                                        class="video-case"
                                        :src="element.url"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>
                                </div>

                                <div class="ql-snow" v-if="element.type == 'santa'">
                                    <div class="text-content p-1">SANTA JUMP</div>
                                </div>
                            </div>

                            <div class="col-auto d-flex flex-column align-items-center justify-content-center">
                                <button
                                    @click="updateSection(index, element.id)"
                                    v-bind:class="`btn-${client}`"
                                    class="btn btn-sm text-white mb-2"
                                >
                                    <i class="fa-solid fa-pencil fa-lg"></i>
                                </button>

                                <button
                                    @click="openDeleteModal(index, element.id)"
                                    v-bind:class="`btn-${client}`"
                                    class="btn btn-sm text-white"
                                >
                                    <i class="fa-regular fa-trash-can fa-lg"></i>
                                </button>
                            </div>

                            <div class="col-12 my-2">
                                <hr class="border-hr" />
                            </div>
                        </div>
                    </div>

                    <div class="text-center">
                        <!-- Bouton pour ajouter une section a la fin -->

                        <button @click="addSection(elements.length)" class="btn btn-sm btn-secondary text-white mb-3">
                            <i class="fa-solid fa-plus"></i>

                            <span class="ms-2">Ajouter une section ici</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="content w-100 position-absolute" v-if="!elements" style="top: 35%">
            <div class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg mw-100 text-center">
                <h1 class="mt-3 mb-2 label-date">Chargement du contenu...</h1>
            </div>
        </div>

        <div class="x" v-if="!elements">
            <img class="y" src="@/assets/img/gift.png" alt="codepen" />
        </div>
    </div>
</template>

<script>
import { getDayContent, deleteSectionContent } from "../services/cases-service";
import auth from "../services/auth";
import loader from "../services/loader";
import { getCurrentClient } from "../services/client";
import router from "../services/router";
import { VueEditor } from "vue2-editor";

const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export default {
    components: {
        VueEditor,
    },
    data: function () {
        const day = this.$route.params.day;
        const currentDate = new Date(Date.UTC(2023, 11, day, 0, 0, 0));

        const options = {
            weekday: "long",
            month: "long",
            day: "numeric",
        };

        return {
            currentDate: capitalize(currentDate.toLocaleDateString("fr-FR", options)),
            day,
            elements: null,
            client: getCurrentClient(),
            displayModal: false,
            sectionToDelete: null,
        };
    },
    methods: {
        openPreview: function () {
            localStorage.day = this.day;
            let routeData = this.$router.resolve({ name: "case" });
            window.open(routeData.href, "_blank");
        },
        openCaseEditor: function (day) {
            router.push({ name: "case-editor", params: { day: day } });
        },
        addSection(index) {
            router.push({
                name: "edit-contenu",
                params: { day: this.day, index: index, id: null },
            });
        },
        updateSection(index, id) {
            router.push({
                name: "edit-contenu",
                params: { day: this.day, index: index, id: id },
            });
        },
        openDeleteModal(index, id) {
            this.sectionToDelete = { index, id };
            this.displayModal = true;
        },
        closeDeleteModal() {
            this.sectionToDelete = null;
            this.displayModal = false;
        },
        async deleteSection() {
            loader.showLoader();
            let params = {
                day: this.day,
                client: this.client,
                id: this.sectionToDelete.id,
                ordre: this.sectionToDelete.index,
            };

            // suppression de la section
            await deleteSectionContent(params)
                .then((result) => {
                    if (result) {
                        this.elements.splice(this.sectionToDelete.index, 1);
                    } else {
                        alert("Une erreur est survenue");
                    }
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });

            // actualisation de l'ordre des sections suivante
            for (const el of this.elements) {
                if (el.ordre >= this.sectionToDelete.index) el.ordre--;
            }

            this.displayModal = false;
            loader.hideLoader();
        },
    },
    asyncComputed: {
        async elements() {
            var _elements = [];
            const day = this.$route.params.day || localStorage.day;

            await getDayContent({ day, token: auth.getToken() })
                .then((result) => {
                    const data = result.data;
                    _elements = data;
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });

            return _elements;
        },
    },
};
</script>

<style scoped>
.border-hr {
    border-color: #777878;
}
</style>
