<template>
  <div class="home flex-fill text-center d-flex-center position-relative">
    <img v-if="client == 'demo'" src="../assets/clients/demo/tuto-logo.svg" class="position-absolute" alt=""
      style="max-width: 400px;left: -31px;top: -41px;transform: rotate(-45deg);" />
    <img v-if="client == 'demo'" src="../assets/clients/demo/tuto-bg.svg" class="position-absolute" alt=""
      style="max-width:400px;right:0;top:0;" />
    <img v-if="client == 'demo'" src="../assets/clients/demo/tuto-message.svg" class="position-absolute" alt=""
      style="max-width: 400px;right:5%;" />

    <div class="flex-fill text-center py-4 img-presentation">
      <div class="position-relative">
        <img v-if="imgAccueilUrl" :src="imgAccueilUrl" class="img-presentation mt-3 rounded" alt="" />
        <button @click="openParticipantsLoterie" v-if="client === 'simt'" v-bind:class="`bg-${client}`"
          class="btn-home-simt"></button>
      </div>
      <div>
        <router-link class="btn btn-lg mt-3" v-bind:class="`btn-${client}`" to="/calendrier">
          Ouvrir mon calendrier
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentClient, getImgAccueilUrl, isStarted } from "../services/client";

export default {
  data() {
    let _client = getCurrentClient();

    return {
      isStarted: isStarted(),
      client: _client,
      imgAccueilUrl: getImgAccueilUrl(_client),
    };
  },
  methods: {
    openParticipantsLoterie() {
      window.open("/ressources/Liste des participants 2023 - Numéro participants.pdf", "_blank");
    },
  },
};
</script>

<style>
.btn-home-simt {
  left: 17%;
  opacity: 0;
  position: absolute;
  width: 14.5%;
  height: 3%;
  top: 53.5%;
  cursor: pointer;
}

.btn-home-simt:active,
.btn-home-simt:hover {
  opacity: 0.2;
}
</style>