<template>
    <div class="container not-found mt-5 pt-5 text-center">
        <h1 v-bind:class="`text-${currentClient}`" style="font-size: 2.5rem" class="fw-bold">Page introuvable</h1>
        <router-link to="/" class="text-decoration-none">Retour à l'accueil</router-link>
    </div>
</template>

<script>
import { getCurrentClient } from "@/services/client";

export default {
    data: () => ({
        currentClient: getCurrentClient(),
    }),
};
</script>

<style>
body {
    background-color: #212529 !important;
}
</style>
