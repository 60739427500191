<template>
    <div class="login flex-fill d-flex-center py-3">
        <div class="form-signin">
            <form @submit.prevent="login">
                <router-link to="/">
                    <img style="max-height: 200px; max-width: 300px" :src="logoUrl" alt="" />
                </router-link>

                <h1 @click="fill" class="h3 my-3 font-weight-normal text-white">CONNEXION</h1>

                <div class="px-3 mb-2">
                    <input
                        v-model="code"
                        type="text"
                        id="inputCode"
                        v-bind:class="`border-color-focus-${client}`"
                        class="form-control fw-bold text-uppercase"
                        placeholder="Saisir code"
                        maxlength="15"
                        autofocus
                    />
                </div>

                <p v-if="error" class="text-danger">Code invalide</p>

                <button v-bind:class="`btn-${client}`" class="btn btn-lg text-white w-100 my-2">
                    Se connecter
                </button>
                <p class="mt-1 mb-0 text-gray-2 small">&copy; INNOV'events - {{ new Date().getFullYear() }}</p>
            </form>
        </div>
    </div>
</template>

<script lang="js">
import auth from "../services/auth";
import loader from "../services/loader";
import { getCurrentClient, getLogoUrl } from "../services/client";

export default {
    data() {
        let _client = getCurrentClient();

        return {
            code: "",
            error: false,
            client: _client,
            logoUrl: getLogoUrl(_client),
        };
    },
    methods: {
        async login() {
            loader.showLoader();

            await auth.login(this.client, this.code, (isLoggedIn) => {
                if (!isLoggedIn) {
                    this.error = true;
                } else {
                    this.$router.replace(this.$route.query.redirect || "/");
                }
            });

            this.$gtag.event("login", { event_category: "general", event_label: this.client });

            loader.hideLoader();
        },
        fill() {
            if (process.env.NODE_ENV == "development") {
                this.code = "DIJON"
            }
        },
    },
};
</script>
